html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #fff; */
  /* background: #171717; */
  /* color: #fff; */
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.calendar {
  height: 250px;
  /* max-width: 230px; */
  margin: 0 auto;
}

.calendar,
.calendar-month {
  width: 100%;
}
.calendar-month_header {
  color: #c6c6c6;
  font-size: 18px;
  font-weight: 500;
}
.calendar-month_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* displ */
}
.calendar-days_of_week {
  color: #747474;
  font-size: 12px;
  padding: 10px 0 5px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(7, 14.28%);
  grid-template-columns: repeat(7, 14.28%);
  width: 100%;
  text-align: center;
  /* displ */
}

.calendar-week {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: repeat(7, 14.28%);
  grid-template-columns: repeat(7, 14.28%);
  width: 100%;
  /* justify-content: space-evenly; */
}
.calendar-week-day {
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  /* display: block */
  justify-content: center;
  width: auto;
  display: block;
  height: 32px;
  border-radius: 3px;
  text-align: center;
}

.calendar-week-day.is-today {
  background: #5b644a;
}

.calendar-week-day.is-selected {
  background: #a3b878;
}
.calendar-week-day:hover {
  background: #5b644a;
}

.calendar-header_button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.calendar-header_button::after {
  content: '';
  display: block;
  width: 2px;
  height: 9px;
  background: #5b644a;
  transform: rotate(-45deg) translateY(-3px) translateX(3px);
}

.calendar-header_button::before {
  content: '';
  display: block;
  width: 2px;
  height: 9px;
  background: #5b644a;
  transform: rotate(45deg);
}

.calendar-header_button.is-next {
  transform: rotate(180deg);
}
